export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const authStore = useAuthStore();
  const { logOut } = authStore;
  const { loggedIn } = storeToRefs(authStore);
  const { userData } = storeToRefs(useUserStore());

  if (!loggedIn.value) {
    await logOut();
  }

  if (!userData.value.isEmailVerified) {
    return;
  }

  if (userData.value.type === 'shipper') {
    return await navigateTo('/dashboard/post-load');
  }

  if (userData.value.type === 'driver') {
    return await navigateTo('/dashboard/loads');
  }

  return await navigateTo('/');
});
